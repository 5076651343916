var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"custom-class":"rule-preview-dialog common-dialog","visible":_vm.visible,"title":'你创建的优化规则' + _vm.data.name},on:{"close":_vm.handleClose}},[_c('p',{staticClass:"mb-10"},[_vm._v(" "+_vm._s(_vm.data.tip)+" ")]),_c('p',{staticClass:"py-10 f-16",staticStyle:{"color":"rgb(165, 167, 170)"}},[_vm._v(" 系统会自动执行以下规则： ")]),_c('p',{staticClass:"mb-10"},[_vm._v(" "+_vm._s(_vm.data.conStr)+" ")]),_c('el-form',{ref:"previewFormRef",staticStyle:{"margin-top":"30px"},attrs:{"model":_vm.formModel,"rules":_vm.formRules,"label-width":"110px","size":"small"}},[_c('el-form-item',{attrs:{"prop":"type"}},[_c('el-radio-group',{model:{value:(_vm.formModel.type),callback:function ($$v) {_vm.$set(_vm.formModel, "type", $$v)},expression:"formModel.type"}},_vm._l((_vm.radios),function(item,index){return _c('el-radio',{key:index,staticClass:"dp-flex",staticStyle:{"padding":"5px 0"},attrs:{"label":item.value}},[_c('span',[_vm._v(_vm._s(item.name))]),(index == 2)?_c('el-form-item',{staticClass:"pr",staticStyle:{"top":"-9px"},attrs:{"prop":"num","rules":{
              validator: (rule, value, callback) =>
                _vm.handleValidateTimes(rule, value, callback, {
                  type: _vm.formModel.type
                }),
              trigger: ['change', 'blur']
            }}},[_c('el-input',{staticStyle:{"width":"50px"},attrs:{"size":"mini"},model:{value:(_vm.formModel.num),callback:function ($$v) {_vm.$set(_vm.formModel, "num", $$v)},expression:"formModel.num"}}),_vm._v("小时内最多一次 ")],1):_vm._e()],1)}),1)],1),_c('el-form-item',{attrs:{"prop":"status"}},[_c('el-checkbox',{model:{value:(_vm.formModel.status),callback:function ($$v) {_vm.$set(_vm.formModel, "status", $$v)},expression:"formModel.status"}},[_vm._v("创建后立即生效")])],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.handleClose}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }