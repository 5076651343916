<template>
  <el-form
    size="small"
    label-width="120px"
    :model="formModel"
    :rules="formRules"
    class="time-frequency-form"
  >
    <el-form-item label="运行时间：">
      <el-form-item prop="startTime">
        <el-date-picker
          clearable
          v-model="formModel.startTime"
          align="right"
          type="date"
          placeholder="开始时间"
          :picker-options="pickerOptionsStart"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item prop="endTime">
        <el-date-picker
          clearable
          v-model="formModel.endTime"
          align="right"
          type="date"
          placeholder="结束时间"
          :picker-options="pickerOptionsEnd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
    </el-form-item>
    <el-form-item
      :class="{ 'cus-item': formModel.schedule_type != '每天' }"
      label="必选项："
      required
    >
      <el-form-item prop="schedule_type">
        <el-dropdown
          size="mini"
          placement="bottom-start"
          @command="
            command => {
              formModel.schedule_type = command;
            }
          "
        >
          <span class="every-day">{{ formModel.schedule_type }}</span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in dailTimes"
              :key="index"
              :command="item.value"
              >{{ item.value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>
      <template v-if="formModel.schedule_type == '每天'">
        <span>的</span>
        <el-form-item
          prop="moment"
          :rules="{
            validator: (rule, value, callback) =>
              handleValidateMoment(rule, value, callback, {
                schedule_type: formModel.schedule_type
              }),
            trigger: ['change', 'blur']
          }"
        >
          <el-time-select
            clearable
            size="mini"
            style="width:120px;"
            v-model="formModel.moment"
            placeholder="选择时间"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
          >
          </el-time-select>
        </el-form-item>
      </template>
      <span>检查一次</span>
      <template v-if="formModel.schedule_type == '每天'">
        <span style="color: rgb(157, 160, 163);">{{
          $store.getters.currentAccountInfo.timezoneName
        }}</span>
        <el-tooltip placement="right" content="广告帐户设置的时区">
          <i
            class="el-icon-warning-outline color-theme cursor-pointer pr"
            style="top:8px;"
          ></i>
        </el-tooltip>
      </template>
    </el-form-item>
    <!-- schedule_custom -->
    <el-form-item prop="schedule_custom">
      <el-checkbox-group size="small" v-model="formModel.schedule_custom">
        <el-checkbox-button
          v-for="(item, index) in schedule_customs"
          :key="index"
          :label="item.name"
          >{{ item.name }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    dailTimes: {
      type: Array,
      default: () => []
    },
    schedule_customs: {
      type: Array,
      default: () => []
    },
    initData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formModel: {
        startTime: "",
        endTime: "",
        schedule_custom: [],
        schedule_type: "每天",
        moment: "02:00"
      },
      formRules: {},
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      pickerOptionsEnd: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 3600 * 1000;
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          }
        ]
      },
      joinStrs: ["到", "", "的", "的", "检查一次"]
    };
  },
  methods: {
    handleValidateMoment(rule, value, callback, { schedule_type }) {
      if (schedule_type == "每天" && !value) {
        callback(new Error("时刻必填！"));
      } else {
        callback();
      }
    },
    createCusTimeFn(val) {
      let props = Object.keys(val),
        joinStrs = this.joinStrs,
        joinStrsCopy = JSON.parse(JSON.stringify(joinStrs)),
        propsCopy = JSON.parse(JSON.stringify(props)),
        str = "";
      if (val.schedule_type != "每天") {
        propsCopy.splice(-1, 1);
        joinStrsCopy.splice(3, 1);
        props = propsCopy;
        joinStrs = joinStrsCopy;
        // console.log("props,joinStrs", props, joinStrs);
      }
      props.forEach((p, i) => {
        if (p == "schedule_custom") {
          str += val[p] ? val[p].join("、") : "" + joinStrs[i];
        } else {
          str += val[p] ? val[p] + joinStrs[i] : "";
        }
      });
      return str;
    },
    emitFormModel(){
      this.$emit(
          "post-time_frequency",
          this.createCusTimeFn(this.formModel),
          this.formModel
        );
    }
  },
  watch: {
    formModel: {
      handler(val) {
        // console.log(
        //   "post-time_frequency=>this.createCusTimeFn(val),this.formModel",
        //   this.createCusTimeFn(val),
        //   this.formModel
        // );
        this.emitFormModel()
      },
      deep: true
    },
    initData: {
      handler(val) {
        if (val != {}) {
          let keys = Object.keys(val);
          keys.forEach(k => {
            this.formModel[k] = val[k];
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.time-frequency-form {
  .every-day {
    padding: 5px 7px;
    border-bottom: 1px solid $theme;
  }
  .el-form-item {
    .el-form-item__content {
      @extend .dp-flex;
      @extend .justify-btw;
    }
    &.cus-item {
      .el-form-item__content {
        justify-content: unset;
      }
    }
  }
  .el-checkbox-button__inner {
    margin-right: 5px;
    border-left: 1px solid #dcdfe6;
  }
}
</style>