<template>
  <el-dialog
    custom-class="rule-preview-dialog common-dialog"
    :visible="visible"
    :title="'你创建的优化规则' + data.name"
    @close="handleClose"
  >
    <p class="mb-10">
      {{ data.tip }}
    </p>
    <p class="py-10 f-16" style="color: rgb(165, 167, 170);">
      系统会自动执行以下规则：
    </p>
    <p class="mb-10">
      {{ data.conStr }}
    </p>
    <el-form
      ref="previewFormRef"
      :model="formModel"
      :rules="formRules"
      label-width="110px"
      size="small"
      style="margin-top:30px;"
    >
      <el-form-item prop="type">
        <el-radio-group v-model="formModel.type">
          <el-radio
            v-for="(item, index) in radios"
            :key="index"
            :label="item.value"
            class="dp-flex"
            style="padding:5px 0;"
          >
            <span>{{ item.name }}</span>
            <el-form-item
              v-if="index == 2"
              prop="num"
              class="pr"
              style="top:-9px;"
              :rules="{
                validator: (rule, value, callback) =>
                  handleValidateTimes(rule, value, callback, {
                    type: formModel.type
                  }),
                trigger: ['change', 'blur']
              }"
            >
              <el-input
                size="mini"
                v-model="formModel.num"
                style="width:50px;"
              ></el-input
              >小时内最多一次
            </el-form-item>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="status">
        <el-checkbox v-model="formModel.status">创建后立即生效</el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >提交</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Object,
      default: () => {}
    },
    initModel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      type: 0,
      formModel: { type: 0, status: false, num: "" },
      formRules: {},
      radios: [
        { value: 0, name: "每次匹配后执行" },
        { value: 1, name: "仅一次" },
        { value: 2, name: "每个广告系列" }
      ]
    };
  },
  methods: {
    handleValidateTimes(rule, value, callback, { type }) {
      if (type == 2 && !value) {
        callback(new Error("次数必填！"));
      } else {
        callback();
      }
    },
    handleClose() {
      this.$refs.previewFormRef.resetFields();
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$refs.previewFormRef.validate(valid => {
        if (valid) {
          this.$emit("confirm-set-rule", this.formModel);
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    initModel: {
      handler(val) {
        if (val && val != {}) {
          let keys = Object.keys(val);
          keys.forEach(k => {
            this.formModel[k] = val[k];
          });
        }
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.rule-preview-dialog {
  width: 600px;
  .el-dialog__header {
    @extend .dp-flex;
  }
  .el-dialog__title {
    @extend .text-a-c;
  }
  .el-radio__label {
    @extend .dp-flex;
  }
}
</style>